<template>
  <div class="pp-entity__header-actions">
    <GlButton
      v-if="manageEntityButtons"
      class="gl-button--delete"
      :icon-only="!isDesktop"
      title="Delete Entity"
      @click="$emit('clickDelete')"
    >
      <template #beforeTitle>
        <gl-icon
          class="mr-2"
          height="16"
          name="delete-responsive"
          width="16"
        />
      </template>
    </GlButton>
    <GlButton
      v-if="manageEntityButtons"
      :icon-only="!isDesktop"
      pale-light
      title="Edit Entity"
      @click="
        $router.push({
          name: 'edit-entity-management',
          params: { entityId: $route.params.entityId },
        })
      "
    >
      <template #beforeTitle>
        <gl-icon
          class="mr-2"
          height="20"
          name="pp-edit"
          width="20"
        />
      </template>
    </GlButton>
    <GlButton
      v-if="createEntityButtons"
      :icon-only="!isDesktop"
      pale-dark
      title="Create New Entity"
      @click="$router.push({ name: 'create-entity-management' })"
    >
      <template #beforeTitle>
        <gl-icon
          class="mr-2"
          height="20"
          name="pp-add"
          width="20"
        />
      </template>
    </GlButton>
    <GlButton
      v-if="publishEntityButtons"
      :icon-only="!isDesktop"
      pale-dark
      title="Publish"
      @click="$emit('openConfirmModal')"
    >
      <template #beforeTitle>
        <gl-icon
          class="mr-2"
          height="20"
          name="pp-publish"
          width="20"
        />
      </template>
    </GlButton>
  </div>
</template>

<script>
// Components
import GlButton from '@/components/gl-button'
import GlIcon from '@/components/gl-icon'

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

// Vuex
import { mapGetters } from 'vuex'

export default {
  components: {
    GlButton,
    GlIcon,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    manageEntityButtons() {
      return this.$route.name === 'view-entity-explorer' || this.$route.name === 'view-entity-management' && this.isAdmin
    },
    createEntityButtons() {
      return this.$route.name === 'entity-explorer' || this.$route.name === 'view-entity-explorer' && this.isAdmin
    },
    publishEntityButtons() {
      return this.$route.name === 'create-entity-management' || this.$route.name === 'edit-entity-management' && this.isAdmin
    },
  },
  methods: {
    
  }
}
</script>

<style scoped>
.pp-entity__header-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
}

@media (max-width: 1023px) {
  
}
</style>